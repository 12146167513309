import {Box, Typography, Grid} from '@mui/material';

export default function LinkedInPost({
  id,
  form,
  color,
  image,
  secondaryImage,
  uploadedImage,
  uploadedSecondaryImage,
  logo,
  scale = 1
}) {
  return (
    <Box id={id} backgroundColor="white" width={`${1104 * scale}px`} height={`${552 * scale}px`}>
      <Box
        sx={{background: `linear-gradient(45deg, ${color}30, ${color}10, ${color}20, ${color}90)`}}
        width={`${1104 * scale}px`}
        height={`${552 * scale}px`}
      >
        <Grid
          container
          alignContent="space-between"
          alignItems="center"
          width={`${1104 * scale}px`}
          height={`${552 * scale}px`}
          overflow="hidden"
        >
          <Grid item xs={6} pl={6 * scale} py={6 * scale}>
            {logo && (
              <img
                style={{
                  maxHeight: 80 * scale,
                  maxWidth: '60%',
                  mixBlendMode: 'multiply',
                  filter: 'brightness(1.15)'
                }}
                src={URL.createObjectURL(logo)}
                width="auto"
                alt="logo"
              />
            )}
            <Typography
              sx={{color: color, fontSize: `${4 * scale}rem`}}
              paragraph
              variant="h1"
              mb={3 * scale}
            >
              {form.heading}
            </Typography>
            <Typography sx={{fontSize: 30 * scale, lineHeight: 1.1}} paragraph>
              {form.description}
            </Typography>
            {secondaryImage && (
              <Box mt={4 * scale}>
                <img
                  src={
                    uploadedSecondaryImage
                      ? URL.createObjectURL(uploadedSecondaryImage)
                      : secondaryImage
                  }
                  style={{
                    maxHeight: 100 * scale,
                    maxWidth: '90%',
                    width: 'auto',
                    borderRadius: 5,
                    mixBlendMode: 'multiply'
                  }}
                  alt="secondaryImage"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={6} sx={{minHeight: '100%', display: 'flex'}}>
            <Box
              height="100%"
              width="100%"
              sx={{
                backgroundImage: `url(${
                  uploadedImage ? URL.createObjectURL(uploadedImage) : image
                })`,
                minHeight: 552 * scale,
                minWidth: 552 * scale,
                zIndex: 1,
                backgroundSize: 'cover',
                backgroundPosition: 'left'
              }}
            />
            <svg
              fill={color}
              opacity={0.2}
              style={{marginLeft: '-80%', marginTop: '-40%'}}
              viewBox="0 0 666 666"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="500" cy="500" r="500" />
            </svg>
          </Grid>
          <Grid item xs={12}>
            <Box
              height={`${10 * scale}px`}
              mt={`${-10 * scale}px`}
              sx={{
                zIndex: 99,
                background: `linear-gradient(45deg, ${color}70, ${color}, ${color}90, ${color}80)`
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
