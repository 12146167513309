import {useState, useEffect} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PreviewIcon from '@mui/icons-material/Preview';
import TuneIcon from '@mui/icons-material/Tune';
import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  Button,
  Box,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  AvatarGroup
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import {styled, useTheme} from '@mui/material/styles';
import * as download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import ConfigDrawer from 'components/Drawer/ConfigDrawer';
import LinkedInPost from 'components/LinkedInPost';
import like from 'components/LinkedInPreview/images/like.svg';
import praise from 'components/LinkedInPreview/images/praise.svg';
import support from 'components/LinkedInPreview/images/support.svg';
import pug from 'images/pug.png';
import socialImageLogo from 'images/socialImageLogo.svg';
import {height, width} from 'theme/layout';
import AlertSmallScreen from 'views/AlertSmallScreen';

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  minHeight: '100vh',
  height: '100%',
  padding: theme.spacing(5),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -width.drawer,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  backgroundColor: '#232323',
  backgroundImage: `radial-gradient(${theme.palette.primary.main} 0.4px, #232323 0.4px)`,
  backgroundSize: '8px 8px'
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${width.drawer}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: width.drawer
  }),
  backgroundColor: 'white',
  backgroundImage:
    'repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 8px ), repeating-linear-gradient( #ffede155, #ffede1 )'
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

function useWindowResize(callback) {
  useEffect(() => {
    function handleResize() {
      callback();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [callback]);
}

function useIsMobile() {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(`(max-width: ${theme.breakpoints.values.sm}px)`).matches
  );
  useWindowResize(() =>
    setIsMobile(window.matchMedia(`(max-width: ${theme.breakpoints.values.sm}px)`).matches)
  );
  return isMobile;
}

export default function Layout() {
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({
    heading: 'Add a heading',
    description: 'Add a description'
  });

  const [openLinkedInPostPreview, setOpenLinkedInPostPreview] = useState(false);

  const [pickedColor, setPickedColor] = useState('#8671ff');
  const [image, setImage] = useState(pug);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [secondaryImage, setsecondaryImage] = useState('');
  const [uploadedSecondaryImage, setUploadedSecondaryImage] = useState(null);

  const handleLinkedInPostPreviewClose = () => {
    setOpenLinkedInPostPreview(false);
  };
  const handleLinkedInPostPreviewOpen = () => {
    setOpenLinkedInPostPreview(true);
  };

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleSecondaryImageChange = (event) => {
    setsecondaryImage(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function onInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  const handleDownloadLinkedInPost = async () => {
    htmlToImage.toPng(document.getElementById('linkedin-post')).then(function (dataUrl) {
      download(dataUrl, 'linkedin-post.png');
    });
  };

  const isMobile = useIsMobile();

  if (isMobile) {
    return <AlertSmallScreen />;
  } else {
    return (
      <Box sx={{display: 'flex'}}>
        <AppBar position="fixed" elevation={0} open={open}>
          <Toolbar sx={{height: height.header}}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <img src={socialImageLogo} alt="logo" width="66px" height="66px" />
              <Typography
                sx={{marginLeft: 1}}
                color="primary"
                noWrap
                variant="h3"
                component="div"
                lineHeight={0.9}
              >
                Image
                <br />
                for post
              </Typography>
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{...(open && {display: 'none'})}}
            >
              <TuneIcon fontSize="large" sx={{fontSize: 50}} color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Main open={open}>
          <DrawerHeader />
          <Box>
            <Box display="flex">
              <LinkedInIcon color="primary" fontSize="large" />{' '}
              <Typography variant="h3" color="primary">
                LinkedIn post
              </Typography>
            </Box>
            <ButtonGroup disableElevation aria-label="Disabled elevation buttons">
              <Button
                onClick={handleDownloadLinkedInPost}
                startIcon={<DownloadIcon />}
                size="large"
                variant="outlined"
                disableElevation
                sx={{my: 1}}
              >
                Download 1104px x 552px
              </Button>
              <Button
                onClick={handleLinkedInPostPreviewOpen}
                startIcon={<PreviewIcon />}
                size="large"
                variant="outlined"
                disableElevation
                sx={{
                  my: 1,
                  background: '#00000050',
                  color: 'primary.main',
                  '&:hover': {background: 'transparent', boxShadow: 'none'}
                }}
              >
                Preview
              </Button>
            </ButtonGroup>
            <Dialog onClose={handleLinkedInPostPreviewClose} open={openLinkedInPostPreview}>
              <DialogTitle
                sx={{
                  backgroundColor: '#232323',
                  backgroundImage: `radial-gradient(#ff6900 0.4px, #232323 0.4px)`,
                  backgroundSize: '15px 15px',
                  color: 'primary.main',
                  fontSize: 25
                }}
              >
                Preview
                <IconButton
                  onClick={handleDownloadLinkedInPost}
                  size="large"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                  }}
                >
                  <DownloadIcon fontSize="large" />
                </IconButton>
              </DialogTitle>

              <Box px={3} py={3} backgroundColor="#f3f2ef">
                <Box
                  backgroundColor="white"
                  borderRadius={2}
                  boxShadow="rgba(0,0,0,0.08) 0px 0px 0px 1px"
                  overflow="hidden"
                >
                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          variant="square"
                          src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : null}
                        />
                      </ListItemAvatar>
                      <ListItemText primary="My company" secondary="109,832 followers" />
                    </ListItem>
                  </List>
                  <Typography mx={2} gutterBottom variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id felis in
                    urna egestas euismod. Nulla posuere mauris interdum metus dignissim malesuada.
                  </Typography>
                  <Typography mx={2} paragraph variant="body2" sx={{color: '#0a66c2'}}>
                    #socialmedia #sizeguide #digitalmarketing
                  </Typography>
                  <LinkedInPost
                    scale={0.5}
                    id="post-preview"
                    uploadedImage={uploadedImage}
                    form={form}
                    color={pickedColor}
                    image={image}
                    secondaryImage={secondaryImage}
                    logo={uploadedLogo}
                  />
                  <Box px={2} mt={1} display="flex" alignItems="center">
                    <AvatarGroup sx={{mr: 1}}>
                      <Avatar sx={{width: 24, height: 24}} alt="like" src={like} />
                      <Avatar sx={{width: 24, height: 24}} alt="support" src={support} />
                      <Avatar sx={{width: 24, height: 24}} alt="praise" src={praise} />
                    </AvatarGroup>
                    <Typography fontSize={14} flexGrow={1}>
                      Pablo Picasso and 240 others
                    </Typography>
                    <Typography fontSize={14}>289 comments · 2,671 reposts</Typography>
                  </Box>
                  <Divider sx={{my: 1}} />
                </Box>
              </Box>
            </Dialog>
            <LinkedInPost
              id="linkedin-post"
              uploadedImage={uploadedImage}
              form={form}
              color={pickedColor}
              image={image}
              secondaryImage={secondaryImage}
              logo={uploadedLogo}
            />
          </Box>
        </Main>
        <ConfigDrawer
          open={open}
          handleDrawerClose={handleDrawerClose}
          form={form}
          setForm={setForm}
          onInputChanged={onInputChanged}
          pickedColor={pickedColor}
          setPickedColor={setPickedColor}
          image={image}
          handleImageChange={handleImageChange}
          secondaryImage={secondaryImage}
          handleSecondaryImageChange={handleSecondaryImageChange}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          uploadedSecondaryImage={uploadedSecondaryImage}
          setUploadedSecondaryImage={setUploadedSecondaryImage}
          setUploadedLogo={setUploadedLogo}
          uploadedLogo={uploadedLogo}
        />
      </Box>
    );
  }
}
