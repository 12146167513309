import {keyframes} from '@emotion/react';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import {Box, Typography} from '@mui/material';
import socialImageLogo from 'images/socialImageLogo.svg';

const wiggleAnimation = keyframes`
    0%, 100%    { transform: rotate(0deg) },
    33%   { transform: rotate(0) },
    66%  { transform: rotate(-45deg) }
    90%  { transform: rotate(-45deg) }
`;

export default function AlertSmallScreen() {
  return (
    <Box
      height="100vh"
      width="100vw"
      sx={{
        backgroundColor: '#232323',
        backgroundImage: 'radial-gradient(#ff6900 0.4px, #232323 0.4px)',
        backgroundSize: '6px 6px'
      }}
    >
      <Box p={4} py={6} display="flex" flexDirection="column" alignItems="center">
        <ScreenRotationIcon
          sx={{fontSize: 100, color: 'white', mb: 1, animation: `${wiggleAnimation} 3s infinite`}}
        />
        <Typography variant="h2" mb={3} fontSize={60} color="white" lineHeight={0.9} align="center">
          Please rotate your device
        </Typography>
        <Typography color="white" fontSize={28} lineHeight={1.2} align="center">
          You need at least a big phone to use this tool comfortably
        </Typography>
      </Box>
      <Box
        position="absolute"
        bottom={0}
        width="100vw"
        sx={{
          backgroundColor: 'white',
          backgroundImage:
            'repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 8px ), repeating-linear-gradient( #ffede155, #ffede1 )'
        }}
        display="flex"
        alignItems="center"
      >
        <img src={socialImageLogo} alt="logo" width="100px" height="100px" />
        <Typography
          sx={{marginLeft: 1}}
          color="primary"
          noWrap
          variant="h2"
          fontSize={35}
          component="div"
          lineHeight={0.9}
        >
          Image
          <br />
          for post
        </Typography>
      </Box>
    </Box>
  );
}
