import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from 'theme/theme';
import Layout from 'views/Layout/Layout';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout />
    </ThemeProvider>
  );
}
