import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import {
  List,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  ListItemAvatar,
  Avatar,
  Select,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  Typography,
  Button,
  ListItemText,
  Box
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {TwitterPicker} from 'react-color';
import bowTie from 'images/bowTie.png';
import creditCards from 'images/creditCards.svg';
import melena from 'images/melena.png';
import pug from 'images/pug.png';
import puppy from 'images/puppy.png';
import qrCode from 'images/qrcode.svg';
import sad from 'images/sad.png';
import {width} from 'theme/layout';

const images = [pug, melena, sad, bowTie, puppy];

const secondaryImages = [qrCode, creditCards];

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  backgroundImage:
    'repeating-radial-gradient( circle at 0 0, transparent 0, #FF6900 8px ), repeating-linear-gradient( #fc974f55, #fc974f )'
}));

export default function ConfigDrawer({
  open,
  handleDrawerClose,
  form,
  setForm,
  onInputChanged,
  pickedColor,
  setPickedColor,
  image,
  handleImageChange,
  secondaryImage,
  handleSecondaryImageChange,
  // uploadedImage,
  setUploadedImage,
  // uploadedSecondaryImage,
  setUploadedSecondaryImage,
  setUploadedLogo,
  uploadedLogo
}) {
  return (
    <Drawer
      sx={{
        width: width.drawer,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width.drawer
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <Box display="flex" flexGrow={1} alignItems="center">
          <TuneIcon fontSize="large" sx={{mr: 1}} />
          <Typography variant="h4">Configure</Typography>
        </Box>
        <IconButton onClick={handleDrawerClose} sx={{color: 'white'}}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </DrawerHeader>
      <Box
        sx={{
          backgroundColor: 'white',
          backgroundImage: 'radial-gradient(#fc974f 0.4px, #ffffff 0.4px)',
          backgroundSize: '8px 8px'
        }}
      >
        <Grid container rowSpacing={1} py={3} px={3}>
          <Grid item xs={12}>
            {uploadedLogo && (
              <img
                style={{mixBlendMode: 'multiply', display: 'block', margin: 'auto'}}
                src={URL.createObjectURL(uploadedLogo)}
                width="60%"
                alt="logo"
              />
            )}
            <Button
              endIcon={<AddAPhotoIcon fontSize="large" />}
              sx={{mt: 1}}
              fullWidth
              variant="outlined"
              component="label"
              size="large"
              disableElevation
            >
              Upload logo
              <input
                type="file"
                hidden
                id="image"
                accept="image/*"
                onChange={(event) => {
                  setUploadedLogo(event.target.files[0]);
                }}
              />
            </Button>
            <Divider sx={{my: 2}} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Text
            </Typography>
            <TextField
              label=""
              variant="standard"
              autoComplete="Heading"
              fullWidth
              multiline
              id="heading"
              name="heading"
              aria-describedby="heading"
              value={form.heading}
              onChange={onInputChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label=""
              variant="standard"
              autoComplete="Description"
              multiline
              fullWidth
              id="description"
              name="description"
              aria-describedby="description"
              value={form.description}
              onChange={onInputChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography my={2} variant="h4">
              Color
            </Typography>
            <FormControl fullWidth>
              <TwitterPicker
                color={pickedColor}
                onChange={(color) => {
                  setPickedColor(color.hex);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography my={2} variant="h4">
              Main image
            </Typography>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="image">
                <Typography paragraph>Doggy examples</Typography>
              </InputLabel>
              <Select
                id="image"
                value={image}
                display="flex"
                sx={{display: 'flex', height: 100}}
                onChange={handleImageChange}
              >
                {images.map((image, i) => (
                  <ListItem key={i} value={image} button>
                    <ListItemAvatar>
                      <Avatar variant="square" src={image} />
                    </ListItemAvatar>
                  </ListItem>
                ))}
              </Select>
            </FormControl>
            <Button
              endIcon={<AddAPhotoIcon fontSize="large" />}
              sx={{mt: 1}}
              fullWidth
              component="label"
              size="large"
              variant="outlined"
              disableElevation
            >
              Upload your own
              <input
                type="file"
                hidden
                id="image"
                accept="image/*"
                onChange={(event) => {
                  setUploadedImage(event.target.files[0]);
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography my={2} variant="h4">
              Secondary image
            </Typography>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="secondaryImage">
                <Typography paragraph>Secondary image</Typography>
              </InputLabel>
              <Select
                id="secondaryImage"
                value={secondaryImage}
                display="flex"
                sx={{display: 'flex', height: 100}}
                onChange={handleSecondaryImageChange}
              >
                {secondaryImages.map((secondaryImage, i) => (
                  <ListItem key={i} value={secondaryImage} button>
                    <ListItemAvatar>
                      <Avatar variant="square" src={secondaryImage} />
                    </ListItemAvatar>
                  </ListItem>
                ))}
                <ListItem value={''} button>
                  <ListItemText primary="No image" />
                </ListItem>
              </Select>
              <Button
                endIcon={<AddAPhotoIcon fontSize="large" />}
                sx={{mt: 1}}
                fullWidth
                component="label"
                size="large"
                variant="outlined"
                disableElevation
              >
                Upload your own
                <input
                  type="file"
                  hidden
                  id="image"
                  accept="image/*"
                  onChange={(event) => {
                    setUploadedSecondaryImage(event.target.files[0]);
                  }}
                />
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{my: 2}} />
            <List>
              <ListItem disablePadding>
                {/* Notify Taig if something is not working or if you are missing something */}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
